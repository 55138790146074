import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import M from 'materialize-css';
import { initSesion, obtenerInfoAgente, getInfo, obtenerOtorgantes } from '../services/api.js'

const Index = () => {

    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [token_refresh, setToken_Refresh] = useState('');
    const [tiempo, setTiempo] = useState('');
    const [roles, setRoles] = useState('');
    const [idUser, setIdUser] = useState('');

    useEffect(() => {
        localStorage.clear();
    }, [])

    useEffect(() => {
        localStorage.setItem("username", username);
    }, [username])

    useEffect(() => {
        localStorage.setItem("token", token);
    }, [token])

    useEffect(() => {
        localStorage.setItem("token_refresh", token_refresh);
    }, [token_refresh])

    useEffect(() => {
        localStorage.setItem("tiempo", tiempo);
    }, [tiempo])

    useEffect(() => {
        localStorage.setItem("roles", roles);
    }, [roles])

    useEffect(() => {
        localStorage.setItem("numUser", idUser);
    }, [idUser])

    const login = async () => {

        initSesion(username, password).then((response) => {
            if (response.status === 200) {
                setToken(response.data.access_token);
                setToken_Refresh(response.data.refresh_token)
                setTiempo(response.data.expires_in)
                setRoles(response.data.roles[0])
                if (response.data.roles[0] === "ROLE_SUPER_ADMINISTRADOR") {
                    info();
                } else {
                    infoAgente();
                }

            } else {
                M.toast({ html: 'Verifica usuario y/o contraseña' });
            }
        }).catch((err) => {
            console.error(err);
            console.log("algun error" + err);
            M.toast({ html: 'Verifica usuario y/o contraseña' });
        })

    }

    const infoAgente = async () => {
        obtenerInfoAgente().then((response) => {
            if (response.status === 200) {
                localStorage.setItem("id_user", response.data.payload[0].id);
                localStorage.setItem("email", response.data.payload[0].email);
                localStorage.setItem("apikey", response.data.payload[0].apiKeyOtorgante);
                localStorage.setItem("name", response.data.payload[0].name);
                if (response.data.payload[0].passwordChanged) {
                    setTimeout(() => {
                        history.push('/home');
                    }, 200);
                } else {
                    setIdUser(response.data.payload[0].idUsuario)
                    setTimeout(() => {
                        history.push('/check');
                    }, 200);
                }
            }
        }).catch((err) => {
            console.log("Ocurrió un error al obtener la información: " + err);
            M.toast({ html: 'Revisa tu conexión a internet' });
        })
    }

    const info = async () => {
        getInfo(username, localStorage.getItem("token")).then((response) => {
            if (response.status === 200) {
                localStorage.setItem("apikey", response.data[0].apiKey);
                obtenerOtorgantes().then((data) => {
                    if (data.status === 200) {
                        localStorage.setItem("dataOtorgante", JSON.stringify(data.data.payload));
                        if (response.data[0].passwordChanged) {
                            setTimeout(() => {
                                history.push('/en_proceso');
                            }, 200);
                        } else {
                            setIdUser(response.data[0].idUsuario)
                            setTimeout(() => {
                                history.push('/check');
                            }, 200);
                        }
                    }
                    else {
                        M.toast({ html: 'Revisa tu conexión a internet' });
                    }
                }).catch((error) => {
                    console.log(error);
                })

            } else {
                M.toast({ html: 'Revisa tu conexión a internet' });
            }
        }).catch((err) => {
            console.error(err);
            console.log("algun error" + err);
            M.toast({ html: 'Revisa tu conexión a internet' });
        })
    }

    const recuperar = () => {
        setTimeout(() => {
            history.push({
                pathname: '/check',
                search: '?restablecer=true'
            });
        }, 200);
    }

    const obtenerListaOtorgantes = async () => {
        obtenerOtorgantes().then((data) => {
            if (data.status === 200) {
                localStorage.setItem("dataOtorgante", JSON.stringify(data.data.payload));
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <div >
            <div className="navbar-fixed">
                <nav>
                    <div className="nav-wrapper center black darken-4 white-text">
                        <a href="#!" className="brand-logo-ex"><img src="../images/head_logo.svg" alt="DICIO Logo" height="60px" /></a>
                    </div>
                </nav>
            </div>


            <div className="row">
                <br /><br />

                <div className="card horizontal col s10 m8 l5 mzauto">
                    <div className="card-stacked">
                        <div className="card-content">
                            <span className="card-title black-text text-darken-1"><b>Panel de administración</b></span>
                            <br />
                            <form>
                                <div className="input-field">
                                    <input id="username" type="text" className="validate" defaultValue="" onChange={e => setUsername(e.target.value)} />
                                    <label htmlFor="username" className="active">Usuario</label>
                                </div>
                                <div className="input-field">
                                    <input id="password" type="password" className="validate" defaultValue="" onChange={e => setPassword(e.target.value)} />
                                    <label htmlFor="password" className="active">Contraseña</label>
                                </div>
                                <br></br>
                                <div>
                                    <a onClick={login} className="btn black forcewidth100">ENTRAR</a>
                                </div>

                            </form>
                        </div>
                        <div className="card-action">
                            <a href="#" onClick={recuperar} className="right">RECUPERAR CONTRASEÑA</a>
                        </div>
                    </div>
                </div>


                <div className="loader_container nodisply">
                    <div className="ispinner ispinner-large">
                        <div className="ispinner-blade"></div>
                        <div className="ispinner-blade"></div>
                        <div className="ispinner-blade"></div>
                        <div className="ispinner-blade"></div>
                        <div className="ispinner-blade"></div>
                        <div className="ispinner-blade"></div>
                        <div className="ispinner-blade"></div>
                        <div className="ispinner-blade"></div>
                        <div className="ispinner-blade"></div>
                        <div className="ispinner-blade"></div>
                        <div className="ispinner-blade"></div>
                        <div className="ispinner-blade"></div>
                    </div>
                </div>

            </div>
        </div>

    )
}


export default Index