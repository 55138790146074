import React, { useState, useMemo, useCallback } from "react";
import { useEffect } from "react";
import M from 'materialize-css';
import { useHistory } from "react-router-dom";
import { SketchPicker } from 'react-color';
import HeaderPage from "../components/HeaderPage";
import LogoutPopup from "../components/LogoutPopup";
import { tomarLLamada, finalizarLLamada, obtenerListaTurnos, refreshSesion } from '../services/api.js';
import { Table } from "react-materialize";
import { TableHeader, Pagination } from "../components/DataTable";
import Spinner from '../components/Spinner';
//import useWebSocket from 'react-use-websocket';

let intervalo;

const Home = () => {


  const history = useHistory();
  const [tiempo, setTiempo] = useState('');
  const [hostMinio, setHostMinio] = useState('proddicio');

  const [vencimiento, setVencimiento] = useState('');
  const [status, setStatus] = useState('');

  const [dataAgentes, setdataAgentes] = useState([])
  const [loading, setLoading] = useState(false);

  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 12;
  const headers = [
    { name: "Cliente", field: "cliente", sortable: false },
    { name: "Otorgante", field: "otorgante", sortable: false },
    { name: "No. de espera", field: "espera", sortable: false },
    { name: "Estatus", field: "status", sortable: false }
  ];

  //const socketUrl = 'wss://localhost:8888';

  // const {
  //   sendMessage,
  //   sendJsonMessage,
  //   lastMessage,
  //   lastJsonMessage,
  //   readyState,
  //   getWebSocket
  // } = useWebSocket(socketUrl, {
  //   onOpen: () => console.log('opened'),
  //   //Will attempt to reconnect on all close events, such as server shutting down
  //   shouldReconnect: (closeEvent) => true,
  //   onMessage: (event)=>{mensajeEntrante(event.data)}
  // });

  // const handleClickSendMessage = useCallback(() =>
  //   sendMessage('Hello DICIO'), []);
  //  const mensajeEntrante = (mensaje) =>{
  //    if(mensaje.includes("DICIO")){
  //      obtenerListaAgentes();
  //    }
  //  }

  const dataListaAgentes = useMemo(() => {
    let computedComments = dataAgentes;
    setTotalItems(computedComments.length);
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [dataAgentes, currentPage, sorting]);

  useEffect(() => {
    let user = localStorage.getItem("username")
    if (user === "") {
      history.push('/index');
    }
    let to = localStorage.getItem("token")
    if (to === "") {
      history.push('/index');
    }
    let time = localStorage.getItem("tiempo");
    if (time !== null) {
      setTiempo(time)
    }
    let host = window.location.hostname;
    if (host.includes("adminotorgante.devdicio")) {
      setHostMinio("devdicio");
    } else if (host.includes("adminotorgante.dev.devdicio")) {
      setHostMinio("dev.devdicio");
    }

    obtenerListaAgentes();
    window.scrollTo(0, 0);
    //handleClickSendMessage();
  }, [])

  useEffect(() => {
    try {
      intervalo = setInterval(async () => {
        await  obtenerListaAgentes();
      }, 30000);
    } catch(e) {
      console.log("Error: "+e);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("listaAgentes", JSON.stringify(dataAgentes));
    setdataAgentes(dataAgentes);
  }, [dataAgentes])

  const obtenerListaAgentes = async () => {
    //console.log("obtener lista");
    setLoading(true);
    let statusTurno = 'EN_ESPERA';
    obtenerListaTurnos(statusTurno).then((res) => {
      if (res.status === 200) {
        let data = res.data.payload;
        setdataAgentes(data);
      }
    }).catch((err) => {
      setLoading(false);
      console.log("algun error:", err);
      cerrar();
    })
    setTimeout(() => {
      setLoading(false)
    }, 4000);

  }

  const obtenerValorConfig = (lista, elemento, valorPredeterminado) => {
    const elementoEncontrado = lista.find(function (item) {
      return item.idCampo === elemento
    })
    if (elementoEncontrado) {
      return elementoEncontrado.valor
    }

    return valorPredeterminado
  }



  const cerrar = () => {
    M.toast({ html: 'Tiempo de sesión terminado' });
    clearInterval(intervalo);
    setTimeout(() => {
      history.push('/index');
    }, 200);
  }

  const tomarVideo = async () => {
    let rtoken = localStorage.getItem("token_refresh");
    refreshToken(rtoken);
    clearInterval(intervalo);
    tomarLLamada().then((response) => {
      if (response.status === 200) {
        console.log(response.data);
        if ((response.data.payload).length > 0) {
          let url = window.location.origin
          localStorage.setItem("id_call", response.data.payload[0].id)
          localStorage.setItem("link_call", response.data.payload[0].clienteVideoLink + "?user=" + localStorage.getItem("username") + "&url=" + url + "&agente=true")
          localStorage.setItem("clienteUuid", response.data.payload[0].clienteUuid);
          localStorage.setItem("clienteTrx", response.data.payload[0].clienteTransaccion);
          localStorage.setItem("otorganteUuid", response.data.payload[0].idOtorgante.id);
          setTimeout(() => {
            history.push('/video_llamada');
          }, 200);
        } else {
          M.toast({ html: 'No hay turnos en cola' });
        }
      }
    }).catch((err) => {
      console.error(err);
      console.log("algun error" + err);
      M.toast({ html: 'error:' + err });
    })
  }

  //const handleReset = () => reset()

  const refreshToken = (token) => {
    refreshSesion(token).then((response) => {
      if (response.status === 200) {
        console.log("Se refrescó el token");
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("token_refresh", response.data.refresh_token);
        //handleReset();//reinicia remaining y elapsed
      }
    }).catch(() => {
      console.log("algun error");
      M.toast({ html: 'Se termino tiempo de sesión' });
      setTimeout(() => {
        history.push('/index');
      }, 200);
    })
  }

  return (
    <div>
      <HeaderPage></HeaderPage>
      <div className="row">

        <br /><br />
        <div className="col s12 m11 z-depth-3 mzauto extra_pdd">

          <div className="col s12 jumbotron clear_float">
            <h4>Sala de espera <small>| Video Chat</small></h4>
            <p className="lead">Ve el listado de clientes en espera de ser atendidos.</p>
            <hr className="my-4" />
            <a className="waves-effect waves-light btn red accent-4 forcewidth100" type="button" name="action" onClick={e => tomarVideo(e.target.id)}>TOMAR VIDEO LLAMADA</a>
          </div>
          <br></br>
          <Table>
            <TableHeader
              headers={headers}
              onSorting={(field, order) =>
                setSorting({ field, order })
              }
            />
            <tbody>
              {dataListaAgentes.map((item, index) => (
                <tr key={item.id}>
                  <td>{(item.clienteUuid).split("-")[0]}</td>
                  <td>{item.idOtorgante.nombre}</td>
                  <td>{item.lugar}</td>
                  <td><a href="#!" ><span className="new badge gray">{item.idStatusTurno.id.replace("_", " ")}</span></a></td>
                </tr>
              ))}
            </tbody>
          </Table>
          <br></br>

          <div className="row">
            <div className="col s12 ">
              <div className="centrarDiv">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={page => setCurrentPage(page)}
                />
              </div>
            </div>

          </div>

        </div>

        {/* <div className="fixed-action-btn">
          <a className="btn-floating btn-large blue darken-3 tooltipped" onClick={e => obtenerListaAgentes()} data-position="left" data-tooltip="Actualizar lista">
            <i className="large material-icons">autorenew</i>
          </a>
        </div> */}

      </div>

      <Spinner state={loading}></Spinner>

      <LogoutPopup></LogoutPopup>
    </div>
  )
}

export default Home