import React, { Fragment, useState, useEffect } from 'react'
import { SideNav, SideNavItem } from "react-materialize";
const HeaderPage = (props) => {

    const [host, setHost] = useState('');

    useEffect(() => {
        let host = window.location.pathname;
        setHost(host)
    }, [])

    return (
        <Fragment>
            <div id="header_container_component">
                <div className="navbar-fixed" style={{ zIndex: '999' }}>
                    <nav>
                        <div className="nav-wrapper black darken-4 white-text">
                            <a href={(host !== "/check") ? "/home" : "/index"} className="brand-logo"><img src="../images/head_logo.svg" alt="DICIO Logo" height="60px" /></a>
                            <ul className="right">
                                <SideNav
                                    id="slide-out"
                                    trigger={
                                        (host !== "/check") ?
                                            <li><a data-target="slide-out" className="sidenav-trigger force_display"><i className="material-icons">menu</i></a></li>
                                            :
                                            <span></span>}
                                >
                                    <SideNavItem
                                        user={{
                                            email: localStorage.getItem("email") ? localStorage.getItem("email") : " ",
                                            image: '../images/yuna.png',
                                            name: localStorage.getItem("roles") === "ROLE_SUPER_ADMINISTRADOR" ? localStorage.getItem("username") : localStorage.getItem("name")
                                        }}
                                        userView
                                        className="black darken-4 white-text"
                                    />
                                    <SideNavItem subheader>
                                        Menú
                                </SideNavItem>
                                    {/*
                                        (localStorage.getItem("roles") === "ROLE_SUPER_ADMINISTRADOR") ?
                                            <SideNavItem
                                                href="/usuarios"
                                                waves
                                            >
                                                Usuarios
                                    </SideNavItem>
                                            :
                                            ""
                                    */}
                                    {
                                        (localStorage.getItem("roles") !== "ROLE_SUPER_ADMINISTRADOR") ?
                                            <SideNavItem
                                                href="/home"
                                                waves
                                            >
                                                Sala de espera
                                </SideNavItem>
                                            :
                                            ""
                                    }
                                    <SideNavItem
                                        href="/en_proceso"
                                        waves
                                    >
                                        En proceso
                                </SideNavItem>
                                    <SideNavItem
                                        href="/seguimientos"
                                        waves
                                    >
                                        Seguimientos
                                </SideNavItem>
                                    <SideNavItem
                                        href="/index"
                                        waves
                                    >
                                        <i className="right material-icons">exit_to_app</i>Salir
                                </SideNavItem>
                                    <SideNavItem
                                        href="#!"
                                    >
                                        <small>Version 1.30.09.2</small>
                                    </SideNavItem>
                                </SideNav>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </Fragment>
    );
}

export default HeaderPage;