import React, { cloneElement, useState, useRef } from "react";
import { useEffect } from "react";
import M from 'materialize-css';
import { useHistory } from "react-router-dom";
import HeaderPage from "../components/HeaderPage";
import LogoutPopup from "../components/LogoutPopup";
import { asignarLLamada, finalizarLLamada, getDocuments, getDocumentsZip, enviarVeredicto, compararRostro, obtenerValidaciones, obtenerValidacionRenapo, obtenerValidacionIne, enviarVideo } from '../services/api.js';
import { Tabs, Tab, Modal, Button } from 'react-materialize';
import { saveAs } from 'file-saver';
import Spinner from '../components/Spinner';


const VideoLlamada = () => {

  const history = useHistory();
  const [tiempo, setTiempo] = useState('');
  const [url, setUrl] = useState('');
  const [link, setLink] = useState('');
  const [onload, setOnload] = useState();
  const [documentos, setDocumentos] = useState([]);
  const [uuidUser, setUuidUser] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [veredicto, setVeredicto] = useState('');
  const [src, setSrc] = useState('');
  const [base64Image, setBase64Image] = useState('');
  const [score, setScore] = useState({ text: "STATUS", color: "grey" });
  const [ine, setIne] = useState({ text: "INE", color: "grey" });
  const [renapo, setRenapo] = useState({ text: "RENAPO", color: "grey" });
  const [captura, setCaptura] = useState({ text: "CAPTURA", color: "grey" });
  const [validacionRenapo, setValidacionRenapo] = useState('');
  const [validacionIne, setValidacionIne] = useState('');

  const [b64Data, setb64Data] = useState("");
  const [videoagente, setvideoagente] = useState("");
  const [videocliente, setvideocliente] = useState("");
  const [contador, setcontador] = useState(0);


  const [Stream, setStream] = useState(null);
  const [mediaRecordStream, setRecordStream] = useState(null);
  const [voiceStream, setVoiceStream] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);

  const videoRef = useRef();

  const mergeAudioStreams = (desktopStream, voiceStream) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasDesktop = false;
    let hasVoice = false;
    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      console.log("get audio from desktop");
      // If you don't want to share Audio from the desktop it should still work with just the voice.
      const source1 = context.createMediaStreamSource(desktopStream);
      //const desktopGain = context.createGain();
      //desktopGain.gain.value = 0.7;
      //source1.connect(desktopGain).connect(destination);
      source1.connect(destination);
      hasDesktop = true;
    }

    if (voiceStream && voiceStream.getAudioTracks().length > 0) {
      console.log("get audio from mic");
      const source2 = context.createMediaStreamSource(voiceStream);
      //const voiceGain = context.createGain();
      //voiceGain.gain.value = 0.7;
      //source2.connect(voiceGain).connect(destination);
      source2.connect(destination);
      hasVoice = true;
    }

    return (hasDesktop && hasVoice) ? destination.stream.getAudioTracks() : [];
  };


  /* useEffect(() => {
    try {
      navigator.mediaDevices.getUserMedia({
        video: { width: 1280, height: 720 },
        audio: true
      }).then(currentStream => {
        videoRef.current.srcObject = currentStream;
        startRecording(videoRef.current.captureStream()).then(recordedChunks => {
          let recordedBlob = new Blob(recordedChunks, {
            type: "video/webm"
          });
          sendVideo(recordedBlob);
          // const link = document.createElement('a');
          // link.href = urlCreator.createObjectURL(recordedBlob);
          // link.download = 'RecordedVideo.webm';
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);

          // console.log("Successfully recorded " + recordedBlob.size + " bytes of " +
          //   recordedBlob.type + " media.");
        }).catch(error => {
          console.log("Error", error);
        })
      }).catch(err => {
        console.error("Error:" + err); return null;
      });
    } catch (err) {
      console.log("err", err)
    }
  }, []); */


  // useEffect(() => {
  //   console.log("entre a mediaStream y voiceStream");
  //   async function enableStream() {
  //     try {
  //       const stream = await navigator.mediaDevices.getDisplayMedia({
  //         audio: true,
  //         video: {
  //           mediaSource: "screen",
  //           audio: true,
  //           displaySurface: "browser",
  //         },
  //         width: 640,
  //         height: 480
  //       }).catch(err => { console.error("Error screen:" + err); return null; });

  //       const streamA = await navigator.mediaDevices.getUserMedia({
  //         video: false,
  //         audio: true
  //       })
  //         .catch(err => {
  //           console.error("Error audio:" + err); return null;
  //         });
  //       setVoiceStream(streamA);
  //       setMediaStream(stream);
  //       const tracks = [
  //         ...stream.getVideoTracks(),
  //         ...mergeAudioStreams(stream, streamA)
  //       ];
  //       console.log('Tracks to add to stream', tracks);
  //       setStream(new MediaStream(tracks))
  //       console.log("hay stream junto");
  //     } catch (err) {
  //       console.log("err", err)
  //     }
  //   }

  //   if (Stream === null) {
  //     enableStream();
  //     //enableStreamV();
  //   } else {
  //     console.log("inicio video");
  //     /*
  //     if (mediaStream !== null && voiceStream !== null ) {
  //       console.log("inicio video");
  //       const tracks = [
  //         ...mediaStream.getVideoTracks(), 
  //         ...mergeAudioStreams(mediaStream, voiceStream)
  //       ];
  //       console.log('Tracks to add to stream', tracks);
  //       setStream(new MediaStream(tracks))
  //       console.log("hay stream junto");
  //     }
  //     */
  //   }
  // }, []);

  useEffect(() => {
    console.log("entre a strema");
    if (Stream !== null) {
      console.log("hay mediastream");
      setRecordStream(new MediaRecorder(Stream, {
        mimeType: 'video/webm; codecs=vp8',
        frameInterval: 10,
        bufferSize: 8192,
        frameRate: 10,
      }))
    }
  }, [Stream]);

  // useEffect(() => {
  //   //console.log("mediaRecordStream,", mediaRecordStream);
  //   async function enableRecord() {
  //     console.log("enable record");
  //     const chunks = [];
  //     mediaRecordStream.ondataavailable = e => { console.log(e.data); chunks.push(e.data) };
  //     mediaRecordStream.onstop = e => {
  //       const completeBlob = new Blob(chunks, { type: chunks[0].type });
  //       console.log("termine video:", completeBlob);
  //       setTimeout(() => {
  //         enviarVideo(completeBlob, "ScreenCapture").then(response => {
  //           if (response.status === 200) {
  //             setLoading(false)
  //             setTimeout(() => {
  //               console.log("Se envio el video correctamente");
  //               localStorage.setItem("link_call", "")
  //               history.push('/home');
  //             }, 1000);
  //           }
  //         }).catch(error => {
  //           console.error(error);
  //           if (error.toString().includes("401")) {
  //             cerrar();
  //           }
  //         })
  //       }, 500);

  //       var downloadUrl = window.URL.createObjectURL(completeBlob); // you can download with <a> tag
  //       var a = document.createElement("a");
  //       document.body.appendChild(a);
  //       //a.style = "display: none";
  //       a.href = downloadUrl;
  //       a.download = "videocall";
  //       //a.click();
  //       window.URL.revokeObjectURL(downloadUrl);
  //       //console.log("video descargado");
  //     };
  //     mediaRecordStream.start();
  //   }

  //   if (mediaRecordStream === null) {
  //     console.log("es null mediaRecordStream");
  //   } else {
  //     console.log("ya hay mediaRecordStream");
  //     enableRecord()
  //   }
  // }, [mediaRecordStream]);

  useEffect(() => {

    let user = localStorage.getItem("username")
    if (user === "") {
      history.push('/index');
    }
    let to = localStorage.getItem("token")
    if (to === "") {
      history.push('/index');
    }
    let time = localStorage.getItem("tiempo");
    if (time !== null) {
      setTiempo(time)
    }

    let link_call = localStorage.getItem("link_call");
    if (link_call) {
      setLink(link_call);
    } else {
      M.toast({ html: 'No hay una conexión disponible' });
      //setTimeout(() => {
      history.push('/home');
      //}, 200);
    }
    let url = window.location.origin
    setUrl(url)
    //info()

    let clienteUuid = localStorage.getItem("clienteUuid");
    if (clienteUuid) {
      setUuidUser(clienteUuid);
      obtenerDocumentosCliente(clienteUuid);
    }

  }, [])

  useEffect(() => {
    //console.log("link:", link);
    if(link){
      cambiarStatus()
    }
  }, [link]);

  const cambiarStatus = async () => {
    console.log("entre a cambiar status llamda");
    asignarLLamada().then((response) => {
      if (response.status === 200) {
        console.log(response.data);
      }
    }).catch((err) => {
      console.error(err);
      console.log("algun error" + err);
    })

  }

  const colgarLLamada = async () => {
    /*
    mediaRecordStream.stop();
    voiceStream.getAudioTracks()[0].stop();
    mediaStream.getVideoTracks()[0].stop();
    mediaStream.getAudioTracks()[0].stop();
    //Stream.getTracks().forEach(s=>s.stop())
    */
    if (veredicto) {
      setLoading(true)
      console.log("veredicto: ",veredicto);
      enviarVeredicto(veredicto).then(response => {
        if (response.status === 200) {
          // mediaRecordStream.stop();
          // mediaStream.getVideoTracks()[0].stop();
          // mediaStream.getAudioTracks()[0].stop();
          // voiceStream.getAudioTracks()[0].stop();
          finalizarLLamada().then((response) => {
            if (response.status === 200) {
              //console.log("llamada finalizada");
              localStorage.setItem("link_call", "");
              let iframe = document.getElementById('iframe-videollamada');
              iframe.contentWindow.postMessage('colgar', '*');
              setTimeout(() => {
                history.push('/home');
              }, 5000);
            }
          }).catch((err) => {
            console.error(err);
            console.log("algun error" + err);
          })
        }
      }).catch(error => {
        console.error(error);
        if (error.toString().includes("401")) {
          cerrar();
        }
      })
    } else {
      M.toast({ html: 'Debes seleccionar un veredicto para terminar la llamada' });
    }

  }

  var eventMethod = window.addEventListener
    ? "addEventListener"
    : "attachEvent";
  var eventer = window[eventMethod];
  var messageEvent = eventMethod === "attachEvent"
    ? "onmessage"
    : "message";

  eventer(messageEvent, function (evento) {
    // if (e.origin !== 'http://the-trusted-iframe-origin.com') return;
    if (evento.data) {
      if (evento.data === "Video enviado" || evento.message === "Video enviado") {
        setLoading(false);
        setTimeout(() => {
          history.push('/home');
        }, 200);
      } else {
        setBase64Image(evento.data);
      }
    }
    //console.log(e);
  });

  const obtenerDocumentosClienteZip = () => {
    setLoading(true);
    getDocumentsZip(uuidUser).then((response) => {
      let blob = new Blob([response.data], { type: 'application/zip' });
      let fileName = "documentos-" + uuidUser.split("-")[0] + ".zip"
      saveAs(blob, fileName);
      setLoading(false);
    }).catch((err) => {
      M.toast({ html: 'No hay archivos para descargar' });
      setLoading(false);
    })
  }

  const obtenerDocumentosCliente = (idUser) => {
    getDocuments(idUser).then((response) => {
      let documentosArray = [];
      response.data.payload.links.forEach(documento => {
        if (documento.file !== 'selfie' && documento.file !== 'pdfautorizacion' && documento.file !== 'fingerprint_right' && documento.file !== 'fingerprint_left' && documento.file !== 'ine' && documento.file !== 'renapo') {
          documentosArray.push(documento);
        }
      });
      setDocumentos(documentosArray);
    }).catch((error) => {
      console.error(error);
      if (error.toString().includes("401")) {
        cerrar();
      }
    })
  }

  const obtenerTooltip = (nombreDocumento) => {
    switch (nombreDocumento) {
      case "videoaceptacion":
        return "Mostrar video de aceptación";
      case "domicilio":
        return "Mostrar c. domicilio";
      case "identificacion_back":
        return "Mostrar identificación";
      case "identificacion_front":
        return "Mostrar identificación";
      case "fingerprint_right":
        return "Mostrar huellas";
      case "fingerprint_left":
        return "Mostrar huellas";
      case "capture":
        return "Mostrar rostro";
      default:
        return "";
    }
  }

  const obtenerIcono = (nombreDocumento) => {

    switch (nombreDocumento) {
      case "videoaceptacion":
        return (<span><i className="material-icons left">record_voice_over</i>ACEPTACIÓN</span>);
      case "domicilio":
        return (<span><i className="material-icons left">text_snippet</i>C. DOMICILIO</span>);
      case "identificacion_back":
        return (<span><i className="material-icons left">payment</i>REVERSO</span>);
      case "identificacion_front":
        return (<span><i className="material-icons left">payment</i>FRENTE</span>);
      case "fingerprint_right":
        return (<span><i className="material-icons left">fingerprint</i>DERECHA</span>);
      case "fingerprint_left":
        return (<span><i className="material-icons left">fingerprint</i>IZQUIERDA</span>);
      case "capture":
        return (<span><i className="material-icons left">face</i>ROSTRO</span>);
      default:
        return "";
        break;
    }
  }

  const mostrarDocumento = (link) => {
    window.open(link);
  }

  const tomarCapturaPantalla = () => {
    let iframe = document.getElementById('iframe-videollamada');
    iframe.contentWindow.postMessage('tomarCaptura', '*');
  }

  const verCapturaPantalla = () => {
    setSrc(base64Image);
    setIsModalOpen(true);
  }

  const enviarCaptura = () => {
    setLoading(true);
    compararRostro(uuidUser, base64Image).then(response => {
      console.log(response);
      if (response.status === 200 && response.data.payload.match) {
        setIsModalOpen(false);
        setBase64Image('');
        setLoading(false)
      } else {
        M.toast({ html: 'No se encontraron condidencias, por favor verifique la captura.' });
        setLoading(false)
      }
    }).catch(error => {
      setLoading(false)
      console.error(error);
      if (error.toString().includes("401")) {
        cerrar();
      }
    })
  }

  const eliminarCaptura = () => {
    setBase64Image('');
  }

  const obtenerValidacionesCliente = () => {
    obtenerValidaciones().then(response => {
      console.log(response);
      if (response.status === 200) {
        let cliente = response.data.payload[0];
        obtenerColorValidacionScore(cliente.idStatusScore);
        obtenerColorValidacionIne(cliente.ine);
        obtenerColorValidacionRenapo(cliente.renapo);
        obtenerColorValidacionCaptura(cliente.captura);
        setValidacionRenapo(cliente.renapo);
        setValidacionIne(cliente.ine);
      }
    }).catch(error => {
      console.error(error);
      if (error.toString().includes("401")) {
        cerrar();
      }
    })
  }

  const obtenerColorValidacionScore = (score) => {
    switch (score) {
      case "GREEN":
        setScore({
          text: "STATUS",
          color: "green"
        });
        break;

      case "YELLOW":
        setScore({
          text: "STATUS",
          color: "amber"
        });
        break;
      case "RED":
        setScore({
          text: "STATUS",
          color: "red"
        });
        break;
      case null:
        setScore({
          text: "SIN RESULTADO",
          color: "grey"
        });
        break;
      default:
        break;
    }
  }

  const obtenerColorValidacionIne = (ine) => {
    switch (ine) {
      case "VALIDADO":
        setIne({
          text: "INE",
          color: "green"
        });
        break;

      case "INVALIDO":
        setIne({
          text: "INE",
          color: "red"
        });
        break;
      case "ERROR":
        setIne({
          text: "INE",
          color: "red"
        });
        break;
      case "PENDIENTE":
        setIne({
          text: "INE",
          color: "amber"
        });
        break;
      case null:
        setIne({
          text: "SIN RESULTADO",
          color: "grey"
        });
        break;
      default:
        break;
    }
  }

  const obtenerColorValidacionRenapo = (renapo) => {
    let validacionRenapo = renapo ? renapo.trim() : renapo;
    switch (validacionRenapo) {
      case "VALIDADO":
        setRenapo({
          text: "RENAPO",
          color: "green"
        });
        break;

      case "INVALIDO":
        setRenapo({
          text: "RENAPO",
          color: "red"
        });
        break;
      case "ERROR":
        setRenapo({
          text: "RENAPO",
          color: "red"
        });
        break;
      case null:
        setRenapo({
          text: "SIN RESULTADO",
          color: "grey"
        });
        break;
      default:
        break;
    }
  }

  const obtenerColorValidacionCaptura = (captura) => {
    switch (captura) {
      case "VALIDADO":
        setCaptura({
          text: "CAPTURA",
          color: "green"
        });
        break;

      case "INVALIDO":
        setCaptura({
          text: "CAPTURA",
          color: "red"
        });
        break;
      case "ERROR":
        setCaptura({
          text: "CAPTURA",
          color: "red"
        });
        break;
      case null:
        setCaptura({
          text: "SIN RESULTADO",
          color: "grey"
        });
        break;
      default:
        break;
    }
  }

  const cerrar = () => {
    M.toast({ html: 'Tiempo de sesión terminado' });
    setTimeout(() => {
      history.push('/index');
    }, 200);
  }

  const validarRenapo = () => {
    setLoading(true);
    if (validacionRenapo) {
      setLoading(false);
    } else {
      obtenerValidacionRenapo(uuidUser, localStorage.getItem("id_call")).then(({data:{payload:{item}}}) => {
        let estatus = item[0].status;
        if (item.length > 0) {
          obtenerColorValidacionRenapo(estatus);
          setValidacionRenapo(estatus);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }).catch(error => {
        setLoading(false)
        console.error(error);
        if (error.toString().includes("401")) {
          cerrar();
        }
      })
    }
  }

  const validarIne = () => {
    setLoading(true);
    if (validacionIne) {
      setLoading(false);
    } else {
      obtenerValidacionIne(uuidUser, localStorage.getItem("id_call")).then(response => {
        console.log(response);
        let ine = response.data.payload.object[0];
        if (ine) {
          obtenerColorValidacionIne(ine.status);
          setValidacionIne(ine.status);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }).catch(error => {
        setLoading(false)
        console.error(error);
        if (error.toString().includes("401")) {
          cerrar();
        }
      })
    }
  }

  const startRecording = (streamVideo) => {
    let recorder = new MediaRecorder(streamVideo);
    let data = [];

    recorder.ondataavailable = event => data.push(event.data);
    recorder.start();
    console.log("Empieza la gabración");
    //console.log(recorder.state + " for " + (lengthInMS / 1000) + " seconds...");

    let stopped = new Promise((resolve, reject) => {
      recorder.onstop = resolve;
      recorder.onerror = event => reject(event.name);
    });

    let recorded = () => recorder.state === "recording" && recorder.stop();

    return Promise.all([
      stopped,
      recorded
    ])
      .then(() => data);
  }

  const stop = () => {
    videoRef.current.srcObject.getTracks().forEach(track => track.stop());
  }

  /* const sendVideo = (blob) => {
    setLoading(true);
    enviarVideo(blob).then(response => {
      if (response.status === 200) {
        finalizarLLamada().then((response) => {
          if (response.status === 200) {
            console.log("llamada finalizada");
            setTimeout(() => {
              history.push('/home');
            }, 200);
          }
        }).catch((err) => {
          console.error(err);
          console.log("algun error" + err);
        })
      }
    }).catch(error => {
      setLoading(false)
      console.error(error);
      if (error.toString().includes("401")) {
        cerrar();
      }
    })
  } */

  return (
    <div>
      <HeaderPage></HeaderPage>
      <div className="row">
        <br />
        <div className="main_gradient">

          <div className="contentIframe">
            {
              (localStorage.getItem("link_call") !== "") ?
                <iframe
                  id="iframe-videollamada"
                  allowusermedia="true"
                  src={localStorage.getItem("link_call")}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                  className="iframeV"
                  allow="camera *; microphone *; autoplay;"
                />
                :
                <p>No hay link</p>
            }

          </div>

          <div className="agent_actions">
            <div className="foot_call_view_b">
              <Button
                className="btn-floating btn-large waves-effect waves-light red agent-btn-margin"
                node="button"
                tooltip="Para finalizar debes marcar un veredicto"
                waves="light"
                onClick={e => colgarLLamada()}
              >
                <i className="material-icons">call_end</i>
              </Button>
              <Button
                className="btn-floating btn-large waves-effect waves-light red agent-btn-margin"
                node="button"
                tooltip="Captura de pantalla"
                waves="light"
                onClick={e => tomarCapturaPantalla()}
              >
                <i className="material-icons blue">add_a_photo</i>
              </Button>
              <div className="col s12"></div>
            </div>
          </div>

          <div className="agent_info grey lighten-2">


            <div className="col s12">
              <Tabs className="tabs">
                <Tab
                  options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                  }}
                  title="Validaciones"
                >
                  <div id="test1" className="col s12">

                    <div className="info_btns">

                      <a className={"waves-effect waves-light btn " + score.color + " accent-4"} style={{ cursor: 'default' }}><i className="material-icons left">traffic</i>{score.text}</a><span> </span>

                      {/* <a className={"waves-effect waves-light btn " + ine.color + " accent-4" + ((validacionIne === '' || validacionIne === null) ? "" : " default-cursor")} onClick={e => validarIne()}><i className="material-icons left">fingerprint</i>{ine.text}</a><span> </span> */}

                      <a className={"waves-effect waves-light btn " + renapo.color + " accent-4" + ((validacionRenapo === '' || validacionRenapo === null) ? "" : " default-cursor")} onClick={e => validarRenapo()}><i className="material-icons left">folder_shared</i>{renapo.text}</a><span> </span>

                      <a className={"waves-effect waves-light btn " + captura.color + " accent-4"} style={{ cursor: 'default' }}><i className="material-icons left">repeat</i>{captura.text}</a><span> </span>

                      <a className="waves-effect waves-light btn grey darken-3" onClick={e => { obtenerValidacionesCliente() }}><i className="material-icons">refresh</i></a>

                    </div>

                  </div>
                </Tab>
                <Tab
                  options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                  }}
                  title="Documentos"
                >
                  <div id="test2" className="col s12">
                    <div className="info_btns">

                      {(documentos.length > 0) ? documentos.map((objeto, index) => (
                        <span key={index + 1}>
                          <Button
                            className="btn grey darken-3 btn-margin"
                            node="button"
                            tooltip={obtenerTooltip(objeto.file)}
                            waves="light"
                            onClick={e => mostrarDocumento(objeto.link.payload.link)}
                            tooltipOptions={{
                              position: 'top'
                            }}
                          >
                            {obtenerIcono(objeto.file)}
                          </Button>
                        </span>)) :
                        <b className="grey-text text-darken-4">No hay documentos para mostrar</b>
                      }
                      <Button
                        className="btn grey darken-3 btn-margin"
                        node="button"
                        tooltip="Descargar documentos"
                        waves="light"
                        onClick={e => obtenerDocumentosClienteZip()}
                        tooltipOptions={{
                          position: 'top'
                        }}
                      >
                        <span><i className="material-icons left">download</i>DESCARGAR</span>
                      </Button>
                    </div>
                  </div>
                </Tab>
                <Tab
                  options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                  }}
                  title="Capturas"
                >
                  <div id="test3" className="col s12">
                    <div className="info_btns">
                      {base64Image ?
                        <span>
                          <a className="waves-effect waves-light btn grey darken-3 modal-trigger" href="#Modal_captura" onClick={e => { verCapturaPantalla() }}>VER CAPTURA</a><a className="waves-effect waves-light btn red accent-4" onClick={e => { eliminarCaptura() }}>X</a><span> </span>
                        </span> :
                        <b className="grey-text text-darken-4">No hay capturas para mostrar</b>
                      }

                    </div>
                  </div>
                </Tab>
                <Tab
                  options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                  }}
                  title="Veredicto"
                >
                  <div id="test4" className="col s12">
                    <div className="info_btns">

                      <form className="row" action="#">
                        <p className="col s3">
                          <label>
                            <input id="aprobado" className="with-gap" name="group1" type="radio" onClick={e => setVeredicto("APROBADO")} />
                            <span><b className="grey-text text-darken-4">Aprobado</b></span>
                          </label>
                        </p>
                        <p className="col s3">
                          <label>
                            <input id="no_aprobado" className="with-gap" name="group1" type="radio" onClick={e => setVeredicto("NO_APROBADO")} />
                            <span><b className="grey-text text-darken-4">No aprobado</b></span>
                          </label>
                        </p>
                        <p className="col s3">
                          <label>
                            <input id="escalar" className="with-gap" name="group1" type="radio" onClick={e => setVeredicto("ESCALAR")} />
                            <span><b className="grey-text text-darken-4">Escalar</b></span>
                          </label>
                        </p>
                      </form>


                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>

          </div>
        </div>
      </div>
      <Modal
        actions={[
          <span>
            <button className="modal-close waves-effect waves-green btn-flat" type="button">Cancelar</button>
            <button className="waves-effect waves-green btn" type="button" onClick={() => { enviarCaptura() }}>Enviar</button>
          </span>
        ]}
        bottomSheet={false}
        fixedFooter
        header="Mostrar captura"
        id="Modal_captura"
        open={isModalOpen}
        options={{
          dismissible: true,
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}
        root={document.body}
      >
        {/*<ReactCrop
          src={src}
          crop={crop}
          ruleOfThirds
          onChange={crop => setCrop(crop)}
          onImageLoaded={onImageLoaded}
        />*/}
        <div className="App">
          <img src={base64Image} width="320" height="410"></img>
        </div>
        <Spinner state={loading}></Spinner>
      </Modal>

      <Spinner state={loading}></Spinner>

      <LogoutPopup></LogoutPopup>

      <video ref={videoRef} playsInline autoPlay hidden />

    </div>


  )
}



export default VideoLlamada